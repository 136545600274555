import React from 'react';
import {
  Grid,
} from "@material-ui/core";

import {FooterStyle} from './styles/FooterStyle';
import {Box, Button} from "../shared/MaterialUI";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import {ListAltOutlined, SettingsApplicationsOutlined} from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import {useUI} from "../../app/context/ui";
import Typography from "@material-ui/core/Typography";

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const Footer = (props) => {
  const classes = FooterStyle();
  const {dataStatus, dataSeasonStart, updateWeeks, getDataStatus, getDataSeasonStart, onDataSeasonWeeks} = props;
  const {dialogUI} = useUI();
  dlgSettings.onConfirm = () => onDataSeasonWeeks();

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant='contained'
              color='secondary'
              size='large'
              className={classes.button}
              startIcon={<SettingsApplicationsOutlined/>}
              disabled={dataStatus?.isProgress || false}
              onClick={() => {
                if (updateWeeks.length > 0) {
                  dialogUI.current.open('Schedule', 'Are you sure to process this schedule?', dlgSettings);
                }
              }}
            >
              Process
            </Button>
            <Button
              variant='outlined'
              color="primary"
              size='large'
              className={classes.button}
              startIcon={<CachedIcon/>}
              onClick={getDataStatus}
            >
              Reload
            </Button>
            <Button
              variant='outlined'
              color="primary"
              size='large'
              className={classes.button}
              startIcon={<ListAltOutlined/>}
              onClick={getDataSeasonStart}
            >
              Summary Season Start
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {dataStatus?.isProgress && (
            <Box sx={{width: '100%'}}>
              <LinearProgressWithLabel value={dataStatus?.percentage || 0} name={dataStatus?.name} color='secondary'/>
            </Box>
          )
          }
        </Grid>
        <Grid item xs={12} className={classes.wrapperTable}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="auto"
            sx={{mr: 1, ml: 5}}
          >
            <table>
              {dataSeasonStart.length > 0 && (
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  {dataSeasonStart[0]?.markets?.[0]?.frecuency?.map((c, g) => (
                    <th key={g}>{c.name}</th>
                  ))}
                </tr>
                </thead>
              )}
              <tbody>
              {dataSeasonStart.map((e, i) => (
                <React.Fragment key={i}>
                  {e?.markets?.length > 0 ? (
                    e.markets.map((m, f) => (
                      <tr key={`${i}-${f}`}>
                        {f === 0 && (
                          <td rowSpan={e.markets.length}>
                            <Grid container alignItems="center">
                              <Grid item sm={2}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="auto"

                                  sx={{mr: 1, ml: 5}}
                                >
                                  <b>{e.week}</b>
                                </Box>
                              </Grid>
                            </Grid>
                          </td>
                        )}
                        <td>
                          <Typography className={m.isWarning ? classes.paragraph : ''}>
                            {m.name}
                          </Typography>
                        </td>
                        {m?.frecuency?.length > 0 ? (
                          m.frecuency.map((c, g) => <td key={`${i}-${f}-${g}`}>
                            <Typography>
                              {c.amount}
                            </Typography>
                          </td>)
                        ) : (
                          <td colSpan="1">No data available</td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr key={i}>
                      <td colSpan="1">No markets available</td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              </tbody>
            </table>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer;
