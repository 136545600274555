import axios from 'axios';
import {environment} from '../environments';

class ExternalCalendarService {

  constructor() {
    this.http = axios.create({
      baseURL: environment.apiMain,
    });
    this.path = '/external';
  }

  searchPhone(data) {
    return this.http.post(`${this.path}/search-phone`, data);
  }

  getCalendarWithToken(token, year) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return this.http.get(`${this.path}/calendar?year=${year}`, config);
  }

  getPayments(token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    let search = '?render=paginate&filter[status]=1&include=crewService,customerPayment';
    return this.http.get(`${this.path}/payments${search}`, config);
  }

}

export default ExternalCalendarService;
