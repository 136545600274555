import { Box, LinearProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  progressContainer: {
    backgroundColor: 'white',
    width: '100%',
    position: 'relative',
  },
  linearProgress: {
    borderRadius: 0,
    boxShadow: theme.shadows[0.5],
    borderColor: theme.palette.grey[500],
    height: 24,
    position: 'relative',
    backgroundColor: '#e8e8e8',
  },
  progressBar: {
    backgroundColor: theme.palette.secondary.main,
  },
  progressText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#000000',
    fontWeight: 600,
    mixBlendMode: 'hard-light',
  },
});

const LinearProgressWithLabel = (props) => {
  const { classes, value, name } = props;
  return (
    <Box className={classes.progressContainer}>
      <LinearProgress
        variant="determinate"
        value={value}
        className={classes.linearProgress}
        classes={{ bar: classes.progressBar }}
      />
      <Typography className={classes.progressText}>
        {name}… {`${Math.round(value)}%`}
      </Typography>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(LinearProgressWithLabel);
