import React, {useContext, useState} from 'react';

import { WeekStyle } from '../calendar/styles/WeekStyle';
import MenuSchedule from "./service/MenuSchedule";
import {ScheduleManagementContext} from "../../pages/schedule/ScheduleManagement";

const Week = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [weekSelect, setWeekSelect] = useState([]);
  const {dataRender, type} = props;
  const classes = WeekStyle();
  const {weekRegular} = useContext(ScheduleManagementContext);

  const renderSlot = (slot) => {
    let backgroundColor = slot.current.active
      ? slot.current.color
      : slot.holidays.length > 0
        ? slot.color
        : slot.winter.active
          ? slot.winter.color
          : slot.color;
    const cursor = 'pointer';
    let backgroundBaseColor = slot.holidays.length > 0 ? slot.color : backgroundColor;
    const bottomColor = slot.customer.color;
    return (
      <div
        className={classes.ctnBlockWeek}
        style={props.type === "external" ? {cursor: 'auto'} : {cursor}}
        key={`week${slot.week}`}
        onClick={(event) => {
          if (props.type !== "external" && !slot?.disabled) {
            setWeekSelect(slot);
            const shouldOpenMenu = slot.week > (weekRegular - 1) || slot.week < 5;
            setMenuOpen(shouldOpenMenu);
            setAnchorEl(event.currentTarget);
          }
        }}
      >
        <div className='div1' style={{backgroundColor}}>
          {slot.start}
        </div>
        <div className='div2' style={{backgroundColor}}>
          {slot.end}
        </div>
        <div className='div3' style={{backgroundColor: backgroundBaseColor}}>
          {(slot.customer.active || slot.todo?.active) ?
            <div className={classes.ctnCustomerActive}
                 style={{borderBottom: `solid 6px ${bottomColor}`,}}>{slot.week}</div>
            :
            <div className={classes.ctnCustomerActive} style={{borderBottom: `solid 6px white`,}}>{slot.week}</div>
          }
        </div>
      </div>
    );
  };

  return <div>{dataRender.map((e) => renderSlot(e))}
    {type === 'schedule' && (
      <MenuSchedule
        weekSelect={weekSelect}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        anchorEl={anchorEl}
        year={props.year}
        dataRender={props.dataRender}
        setDataRender={props.setDataRender}
        getDataSchedule={props.getDataSchedule}
      />
    )}
  </div>;
};

export default Week;
